import React from 'react';

export const Banner00DataSource = {
  wrapper    : {className: 'banner0'},
  textWrapper: {className: 'banner0-text-wrapper'},
  title      : {
    className: 'banner0-title',
  },
  content    : {
    className: 'banner0-content',
    children : '创惠喵 —— 区块链赋能新零售',
  },
};
export const Content00DataSource = {
  wrapper     : {className: 'home-page-wrapper content0-wrapper'},
  page        : {className: 'home-page content0'},
  OverPack    : {playScale: 0.3, className: ''},
  titleWrapper: {
    className: 'title-wrapper',
    children : [{name: 'title', children: '产品与服务'}],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children : [
      {
        name     : 'block0',
        className: 'content0-block',
        md       : 8,
        xs       : 24,
        children : {
          className: 'content0-block-item',
          children : [
            {
              name     : 'image',
              className: 'content0-block-icon',
              children : 'https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png',
            }, {
              name     : 'title',
              className: 'content0-block-title',
              children : '一站式网点接入',
            }, {
              name    : 'content',
              children: '支付、结算、开店 业务效率加倍提升'
            },
          ],
        },
      },
      {
        name     : 'block1',
        className: 'content0-block',
        md       : 8,
        xs       : 24,
        children : {
          className: 'content0-block-item',
          children : [
            {
              name     : 'image',
              className: 'content0-block-icon',
              children : 'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png',
            }, {
              name     : 'title',
              className: 'content0-block-title',
              children : '直观运营展示',
            }, {
              name    : 'content',
              children: '运营数据实时展示 店铺情况一目了然',
            },
          ],
        },
      },
      {
        name     : 'block2',
        className: 'content0-block',
        md       : 8,
        xs       : 24,
        children : {
          className: 'content0-block-item',
          children : [
            {
              name     : 'image',
              className: 'content0-block-icon',
              children : 'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            }, {
              name     : 'title',
              className: 'content0-block-title',
              children : '大数据策略指导',
            }, {
              name    : 'content',
              children: '运营、风控、进补货智能决策 低成本高效益',
            },
          ],
        },
      },
    ],
  },
};
export const Content30DataSource = {
  wrapper     : {className: 'home-page-wrapper content3-wrapper'},
  page        : {className: 'home-page content3'},
  OverPack    : {playScale: 0.3},
  titleWrapper: {
    className: 'title-wrapper',
    children : [
      {
        name     : 'title',
        children : '创惠喵提供专业的服务',
        className: 'title-h1',
      },
      {
        name     : 'content',
        className: 'title-content',
        children : '',
      },
    ],
  },
  block       : {
    className: 'content3-block-wrapper',
    children : [
      {
        name     : 'block0',
        className: 'content3-block',
        md       : 8,
        xs       : 24,
        children : {
          icon       : {
            className: 'content3-icon',
            children :
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: {className: 'content3-text'},
          title      : {className: 'content3-title', children: '线上资源管理'},
          content    : {
            className: 'content3-content',
            children :
              '库存信息实时查看, 在线管理',
          },
        },
      },
      {
        name     : 'block2',
        className: 'content3-block',
        md       : 8,
        xs       : 24,
        children : {
          icon       : {
            className: 'content3-icon',
            children :
              'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          },
          textWrapper: {className: 'content3-text'},
          title      : {className: 'content3-title', children: '专业供货渠道'},
          content    : {
            className: 'content3-content',
            children : '直通厂家，低价采购，优质货物',
          },
        },
      },
      {
        name     : 'block5',
        className: 'content3-block',
        md       : 8,
        xs       : 24,
        children : {
          icon       : {
            className: 'content3-icon',
            children : 'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
          },
          textWrapper: {className: 'content3-text'},
          title      : {className: 'content3-title', children: '大数据'},
          content    : {
            className: 'content3-content',
            children : '大数据预测业务走向，实时提供决策支撑',
          },
        },
      },
    ],
  },
};
export const Footer00DataSource = {
  wrapper  : {className: 'home-page-wrapper footer0-wrapper'},
  OverPack : {className: 'home-page footer0', playScale: 0.05},
  copyright: {
    className: 'copyright',
    children : (
      // eslint-disable-next-line
      <span>晋ICP备2021000715号 ©2021 <a>山西块趣为农网络技术有限公司</a> All Rights Reserved</span>
    ),
  },
};
